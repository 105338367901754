import React from "react"
import { getCourseNavigation } from "../../../store/courses"
import LayoutCourse from "../../../layouts/course"

import Seo from "../../../components/seo"
import Heading from "../../../components/heading"
import TextLink from "../../../components/textlink"
import Stack from "../../../components/stack"
import Box from "../../../components/box"
import CourseFooterNext from "../../../components/course-footer-next"
import Paragraph from "../../../components/paragraph"
import Bullet from "../../../components/bullet"
import BulletList from "../../../components/bullet-list"
import Note from "../../../components/note"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "hoelderlins-reisen" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext
          label="Kurs starten"
          to="/kurse/hoelderlins-reisen/01-friedrich-hoelderlin"
        />
      }
      navigation={navigation}
    >
      <Seo title="Die versteckte Ordnung der Gedichte" />
      <Box>
        <Stack>
          <Heading as="h2" level={2}>
            Informationen für Lehrer*innen
          </Heading>
          <Paragraph dropcap={true}>
            In diesem Kurs lernen die Schüler*innen den Dichter Friedrich
            Hölderlin kennen und setzen sich mit den Zeugnissen seiner ersten,
            noch als Schüler unternommenen Reise an den Rhein auseinander.
          </Paragraph>

          <Paragraph>
            Im{" "}
            <TextLink to="/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/">
              1. Kapitel
            </TextLink>{" "}
            erfahren sie, dass sein Leben von vielen Ortswechseln geprägt war
            und nähern sich ihm über die Betrachtung eines Porträts, das den
            jungen Hölderlin als Schüler zeigt. Aus einem Abschnitt seines
            Reisetagebuchs leiten sie die Stationen seiner Reise ab, erfahren
            von Hölderlins Liebe zum Wasser und errechnen seine Reisekosten.
          </Paragraph>

          <Paragraph>
            Anschließend lesen sie einen Brief, in dem Hölderlin seiner Mutter
            von der abenteuerreichen Anreise berichtet, korrigieren Hölderlins
            Rechtschreibfehler und entwickeln dazu eine Bildergeschichte in
            Emojis. Daran anknüpfend schreiben die Schüler*innen den
            Reisebericht weiter: Sie erhalten Impulse für einen Schreibplan,
            einen Hilfestellung zum Textaufbau und Anregungen, um sich in
            Hölderlins Perspektive hineinzuversetzen.
          </Paragraph>

          <Paragraph>
            Im{" "}
            <TextLink to="/kurse/hoelderlins-reisen/02-reiseerinnerung">
              2. Kapitel
            </TextLink>{" "}
            beschäftigen sich die Schüler*innen mit Hölderlins Gedicht
            ›Heidelberg‹, das auf eine Station seiner Reise zurückgeht. Zunächst
            entziffern sie den Gedichttitel aus Hölderlins Handschrift. Über
            eine Fotografie von Barbara Klemm machen sie sich ein Bild von der
            Stadt und gleichen es mit den Beschreibungen des Gedichts ab. Sie
            setzen sich mit der Melodie der Verse und dem Rhythmus der Silben
            auseinander, erfahren von Hölderlins kreativen Wortneuerfindungen
            und von seinem Spiel mit Formen und Mustern. Zuletzt versuchen sich
            die Schüler*innen an einem rhythmisch gestalteten Vortrag des
            Gedichts.
          </Paragraph>
          <Note title="Hinweis">
            Der Kurs kann sowohl von den Schüler*innen eigenständig bearbeitet
            als auch in den Unterricht integriert werden. Alle Einheiten sind
            interaktiv angelegt. Sie fördern eine produktive und kreative
            Erschließung literarischer Formen und Texte, sensibilisieren die
            Schüler*innen für die Machart literarischer Texte und tragen zur
            ästhetischen Bildung bei.
          </Note>
          <Paragraph>
            Die Kapitel sind an folgenden Inhalten des Bildungsplans angelehnt:
          </Paragraph>
          <BulletList size={[3, 3, 4, 5]}>
            <Bullet>Medienbildung & Bildanalyse</Bullet>
            <Bullet>Wörter sammeln & ordnen</Bullet>
            <Bullet>Fehlersensibilität & Rechtschreibgespür</Bullet>
            <Bullet>Sprache & Sprachgebrauch untersuchen</Bullet>
            <Bullet>Leseverstehen entwickeln</Bullet>
            <Bullet>handelnd mit Texten umgehen</Bullet>
            <Bullet>adressaten- und funktionsgerecht schreiben</Bullet>
            <Bullet>Texte planen, verfassen & schreiben</Bullet>
            <Bullet>
              unterschiedliche Schreibwerkzeuge und Schriftzeichen erproben
            </Bullet>
            <Bullet>Sprachliche Strukturen & Begriffe reflektieren</Bullet>
            <Bullet>Medien und Methoden der Texterschließung</Bullet>
            <Bullet>Bedeutung von Intonation & Klangfarbe</Bullet>
            <Bullet>ausdrucksvoll sprechen, vortragen & präsentieren</Bullet>
          </BulletList>
          <Paragraph>
            Die von den Schüler*innen eigenständig erarbeiteten Inhalte können
            in Form eines Unterrichtsgesprächs noch einmal aufgegriffen und
            abgefragt werden. Mögliche Impulse wären beispielsweise:
          </Paragraph>
          <Heading level={4}>Kapitel 1</Heading>
          <BulletList size={[3, 3, 4, 5]}>
            <Bullet>
              Wie wirkte Hölderlin auf dem Porträt auf euch? Welchen Eindruck
              habt ihr von ihm über die Tagebucheinträge bekommen?
            </Bullet>
            <Bullet>
              Warum hat Hölderlin das Geld, das er auf der Reise ausgegeben hat,
              in sein Tagebuch eingetragen?
            </Bullet>
            <Bullet>
              Warum hat Hölderlin eigentlich so viele Rechtschreibfehler
              gemacht?
            </Bullet>
            <Bullet>
              Welche Fortsetzung habt ihr euch für Hölderlins Reise überlegt?
            </Bullet>
          </BulletList>
          <Heading level={4}>Kapitel 2</Heading>
          <BulletList size={[3, 3, 4, 5]}>
            <Bullet>
              In welcher Stadt kam Hölderlin auf seiner Reise vorbei?
            </Bullet>
            <Bullet>
              Was ist euch in der 1. Strophe des Gedichts beim Zählen der Silben
              aufgefallen?
            </Bullet>
            <Bullet>Kann ein Gedicht eine Melodie haben?</Bullet>
            <Bullet>Habt ihr Ideen für eigene Wortneuschöpfungen?</Bullet>
            <Bullet>Möchte jemand das Gedicht vortragen?</Bullet>
          </BulletList>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
